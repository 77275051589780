import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import { SideNavProvider } from 'context/SideNavContext';
import { useGetInfo } from 'hooks/useProfile';
import Cookies from 'js-cookie';
import React, { Fragment, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ROLE_SELLER, ROLE_USER } from 'util/constant';
import {
  default as AdminIcon10,
  default as AdminIcon11,
  default as AdminIcon12,
  default as AdminIcon4,
  default as AdminIcon5,
  default as AdminIcon6,
  default as AdminIcon7,
  default as AdminIcon8,
  default as AdminIcon9,
} from '../../assets/images/admin_icon_4.svg';
import SellerIcon1 from '../../assets/images/seller_icon_1.svg';
import SellerIcon2 from '../../assets/images/seller_icon_2.svg';
import SellerIcon3 from '../../assets/images/seller_icon_3.svg';
import SellerIcon4 from '../../assets/images/seller_icon_4.svg';
import styles from './styles.module.scss';
import { STAFF_ROLE } from '../../common/index';
import routeLoader from '../../common/routeLoader';
import GenLinkSocials from 'components/GenLinkSocials';
import useGaTracker from 'hooks/user/useGATracking';

const RevenuePage = lazy(() => routeLoader(() => import('pages/Seller/Revenue')));
const DashboardAdPage = lazy(() => routeLoader(() => import('pages/Admin/Dashboard')));
const MasterVideoPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Video')));
const MasterModelPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Model')));
const MasterCategoryPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Category')));
const MasterFPSPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/FPS')));
const MasterDurationPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Duration')));
const MasterSituationPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Situation')));
const MasterTagPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Tag')));
const MasterResolutionPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/Resolution')));
const MasterShotAnglePage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/ShotAngle')));
const MasterNewPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/MasterData/New')));
const CreatorManagementPage = lazy(() => routeLoader(() => import('pages/Admin/Creator')));
const CreatorManagementDetailPage = lazy(() => routeLoader(() => import('pages/Admin/Creator/Detail')));
const ListVideoPage = lazy(() => routeLoader(() => import('pages/User/ListVideo')));
const MasterListVideoPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/ListVideo')));
const MasterListVideoDetailPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/ListVideo/Detail')));
const CreateVideoAdPage = lazy(() => routeLoader(() => import('pages/Admin/VideoManagement/ListVideo/Update')));
const CompareVideo = lazy(() => routeLoader(() => import('pages/User/CompareVideo')));
const DetailVideo = lazy(() => routeLoader(() => import('pages/User/VideoDetail')));
const PlanManagement = lazy(() => routeLoader(() => import('pages/Admin/PlanManagement/ListPlan')));
const MyPageUser = lazy(() => routeLoader(() => import('pages/User/MyPageUser')));
const ListVideoSellerPage = lazy(() => routeLoader(() => import('pages/Seller/ListVideo')));
const ListVideoDetailSellerPage = lazy(() => routeLoader(() => import('pages/Seller/ListVideo/Detail')));
const CreateVideoSellerPage = lazy(() => routeLoader(() => import('pages/Seller/ListVideo/Update')));
const UserManagementPage = lazy(() => routeLoader(() => import('pages/Admin/UserManagement')));
const UserManagementDetailPage = lazy(() => routeLoader(() => import('pages/Admin/UserManagement/Detail')));
const Point = lazy(() => routeLoader(() => import('pages/Admin/PlanManagement/Point')));
const AIPlan = lazy(() => routeLoader(() => import('pages/Admin/PlanManagement/AIPlan')));
const PersonalPlan = lazy(() => routeLoader(() => import('pages/User/PlanPricing/PersonalPlan')));
const CompanyPlan = lazy(() => routeLoader(() => import('pages/User/PlanPricing/CompanyPlan')));
const ByPoint = lazy(() => routeLoader(() => import('pages/User/ByPoint')));
const PlanAI = lazy(() => routeLoader(() => import('pages/User/PlanAI')));
const ListSellerUser = lazy(() => routeLoader(() => import('pages/User/ListSeller')));
const ListCampaign = lazy(() => routeLoader(() => import('pages/Admin/Campaign/List')));
const DetailSeller = lazy(() => routeLoader(() => import('pages/User/DetailSeller')));
const DetailCampaign = lazy(() => routeLoader(() => import('pages/Admin/Campaign/Detail')));
const MyPageSeller = lazy(() => routeLoader(() => import('pages/Seller/MyPageSeller')));
const StaffManagement = lazy(() => routeLoader(() => import('pages/Admin/StaffManagement')));
const SearchFilterUser = lazy(() => routeLoader(() => import('pages/Admin/Campaign/SearchFilterUser')));
const ListNews = lazy(() => routeLoader(() => import('pages/Admin/News')));
const DetailNews = lazy(() => routeLoader(() => import('pages/Admin/News/Detail')));
const CreateNews = lazy(() => routeLoader(() => import('pages/Admin/News/AddEdit')));
const ContactManagement = lazy(() => routeLoader(() => import('pages/Admin/Contact')));
const QAManagement = lazy(() => routeLoader(() => import('pages/Admin/QAManagement')));
const ContactSeller = lazy(() => routeLoader(() => import('pages/Seller/Contact')));
const Footer = lazy(() => routeLoader(() => import('components/Footer')));
const ContactUser = lazy(() => routeLoader(() => import('pages/User/Contact')));
// const QA = lazy(() => routeLoader(() => import('pages/User/QA')));
const QAPage = lazy(() => routeLoader(() => import('pages/User/QAPage')));
const SalesManagement = lazy(() => routeLoader(() => import('pages/Admin/Dashboard/SalesManagement')));
const Purchase = lazy(() => routeLoader(() => import('pages/User/Purchase')));
const SpendManagement = lazy(() => routeLoader(() => import('pages/Admin/Dashboard/SpendManagement')));
const Dashboard = lazy(() => routeLoader(() => import('pages/Seller/Dashboard')));
const LandingPage = lazy(() => routeLoader(() => import('pages/User/LandingPage')));
const Campaign = lazy(() => routeLoader(() => import('pages/User/Campaign')));
const News = lazy(() => routeLoader(() => import('pages/User/News')));
const DetailCardUser = lazy(() => routeLoader(() => import('components/CardNew/DetailCard')));
const ListBanner = lazy(() => routeLoader(() => import('pages/Admin/Banner/list')));
const Setting = lazy(() => routeLoader(() => import('pages/Admin/Setting')));
const RecentVideo = lazy(() => routeLoader(() => import('pages/User/RecentVideo')));
const CategoryPage = lazy(() => routeLoader(() => import('pages/User/CategoryPage')));
const ReataleVideo = lazy(() => routeLoader(() => import('pages/User/RelateVideo')));
const CommercialTransactionLaw = lazy(() => routeLoader(() => import('pages/User/CommercialTransactionLaw')));
const ListModelUser = lazy(() => routeLoader(() => import('pages/User/ListModel')));
const NotFound = lazy(() => routeLoader(() => import('pages/User/NotFound')));
const GenVideo = lazy(() => routeLoader(() => import('pages/User/AiGenVideo')));

export default function PageWrapper() {
  useGaTracker();
  const { t } = useTranslation();
  const history = useHistory();
  const isAuthenticated = !!Cookies.get('token') || !!Cookies.get('refreshToken');
  const { data: infoUser, isFetching } = useGetInfo(isAuthenticated);
  const roleUser = !isFetching && infoUser?.role;
  const urlAdmin = history.location.pathname.includes('admin');
  const urlSeller = history.location.pathname.includes('seller');
  const urlContact = history.location.pathname.includes('contact');
  // const urlListVideo = _.isEqual(history.location.pathname, '/videos');
  const urlListVideo = history.location.pathname === '/videos';
  const isConfirm2Auth = Number(localStorage.getItem('isConfirm2Auth'));
  if (!isFetching && isAuthenticated && infoUser) {
    if (STAFF_ROLE.find((item: any) => item.en === roleUser) && !urlAdmin) return <Redirect to="/admin" />;
    if (roleUser === ROLE_SELLER && !urlSeller) return <Redirect to="/seller" />;
    if (roleUser === ROLE_USER && (urlAdmin || urlSeller)) return <Redirect to="/" />;
  }
  if (urlAdmin && !isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/admin/login',
        }}
      />
    );
  }

  const listRouter = [
    {
      path: '/',
      component: ListVideoPage,
    },
    {
      path: '/seller/revenue',
      component: RevenuePage,
    },
    {
      path: '/admin/dashboard',
      component: DashboardAdPage,
    },
    {
      path: '/admin/master',
      component: MasterVideoPage,
    },
    {
      path: '/admin/master/model',
      component: MasterModelPage,
    },
    // {
    //   path: '/admin/master/category',
    //   component: MasterCategoryPage,
    // },
    // {
    //   path: '/admin/master/fps',
    //   component: MasterFPSPage,
    // },
    // {
    //   path: '/admin/master/duration',
    //   component: MasterDurationPage,
    // },
    // {
    //   path: '/admin/master/situation',
    //   component: MasterSituationPage,
    // },
    // {
    //   path: '/admin/master/tag',
    //   component: MasterTagPage,
    // },
    // {
    //   path: '/admin/master/resolution',
    //   component: MasterResolutionPage,
    // },
    // {
    //   path: '/admin/master/shotAngle',
    //   component: MasterShotAnglePage,
    // },
    {
      path: '/admin/master/:id/new',
      component: MasterNewPage,
    },
    {
      path: '/admin/creators',
      component: CreatorManagementPage,
    },
    {
      path: '/admin/creators/detail/:id',
      component: CreatorManagementDetailPage,
    },
    {
      path: '/landing',
      component: LandingPage,
    },
    {
      path: '/landing/:path',
      component: LandingPage,
    },
    {
      path: '/compare-video',
      component: CompareVideo,
    },
    {
      path: '/videos/detail/:id',
      component: DetailVideo,
    },
    {
      path: '/admin/videos',
      component: MasterListVideoPage,
    },
    {
      path: '/admin/videos/add',
      component: CreateVideoAdPage,
    },
    {
      path: '/admin/videos/detail/:id',
      component: MasterListVideoDetailPage,
    },
    {
      path: '/admin/videos/edit/:id',
      component: CreateVideoAdPage,
    },
    {
      path: '/seller/my-page',
      component: MyPageSeller,
    },
    {
      path: '/my-page',
      component: MyPageUser,
    },
    { path: '/personal-plan', component: PersonalPlan },
    { path: '/company-plan', component: CompanyPlan },
    { path: '/by-point', component: ByPoint },
    { path: '/ai-plan', component: PlanAI },
    {
      path: '/creators',
      component: ListSellerUser,
    },
    {
      path: '/relate-videos',
      component: ReataleVideo,
    },
    {
      path: '/creators/detail/:id',
      component: DetailSeller,
    },
    {
      path: '/contact',
      component: ContactUser,
    },
    {
      path: '/Q&A',
      component: QAPage,
    },
    {
      path: '/purchase',
      component: Purchase,
    },
    {
      path: '/campaign',
      component: Campaign,
    },
    {
      path: '/campaign/detail/:id',
      component: DetailCardUser,
    },
    {
      path: '/news',
      component: News,
    },
    {
      path: '/news/detail/:id',
      component: DetailCardUser,
    },
    {
      path: '/admin/plans',
      component: PlanManagement,
    },
    {
      path: '/admin/plans-ai',
      component: AIPlan,
    },
    {
      path: '/admin/contact',
      component: ContactManagement,
    },
    {
      path: '/admin/points',
      component: Point,
    },
    {
      path: '/seller/videos',
      component: ListVideoSellerPage,
    },
    {
      path: '/seller/videos/add',
      component: CreateVideoSellerPage,
    },
    {
      path: '/seller/videos/detail/:id',
      component: ListVideoDetailSellerPage,
    },
    {
      path: '/seller/videos/edit/:id',
      component: CreateVideoSellerPage,
    },
    {
      path: '/admin/users',
      component: UserManagementPage,
    },
    {
      path: '/admin/users/detail/:id',
      component: UserManagementDetailPage,
    },
    {
      path: '/admin/campaigns',
      component: ListCampaign,
    },
    {
      path: '/admin/campaigns/detail/:id',
      component: DetailCampaign,
    },
    {
      path: '/admin/staffs',
      component: StaffManagement,
    },
    {
      path: '/admin/campaigns/:id/users',
      component: SearchFilterUser,
    },
    {
      path: '/admin/news',
      component: ListNews,
    },
    {
      path: '/admin/news/detail/:id',
      component: DetailNews,
    },
    {
      path: '/admin/news/edit/:id',
      component: CreateNews,
    },
    {
      path: '/admin/news/add',
      component: CreateNews,
    },
    {
      path: '/seller/contact',
      component: ContactSeller,
    },
    {
      path: '/admin/sales',
      component: SalesManagement,
    },
    {
      path: '/admin/spends',
      component: SpendManagement,
    },
    {
      path: '/seller/dashboard',
      component: Dashboard,
    },
    {
      path: '/admin/banners',
      component: ListBanner,
    },
    {
      path: '/admin/setting',
      component: Setting,
    },
    {
      path: '/recent-video',
      component: RecentVideo,
    },
    {
      path: '/category-page',
      component: CategoryPage,
    },
    {
      path: '/commercial-transaction-law',
      component: CommercialTransactionLaw,
    },
    {
      path: '/models',
      component: ListModelUser,
    },
    {
      path: '/admin/qa',
      component: QAManagement,
    },
    {
      path: '/admin/ai/gen_video',
      component: GenVideo,
    },
    {
      path: '/admin/genlink',
      component: GenLinkSocials,
    },
  ];
  const menuTop: any = [
    // {
    //   key: '5',
    //   // text: t('menuTop.menu1'),
    //   text: t('static-page'),
    //   link: '/landing',
    // },
    {
      key: '4',
      text: t('menuTop.menu5'),
      link: '/models',
    },
    {
      key: '1',
      text: t('menuTop.menu2'),
      link: '/creators',
    },
    {
      key: '2',
      text: t('menuTop.menu3'),
      link: '/campaign',
    },
    {
      key: '3',
      text: t('menuTop.menu4'),
      link: '/news',
    },
  ];
  const menuLeftSeller = [
    {
      key: '1',
      text: t('menuSeller.menu1'),
      url: '/seller/dashboard',
      icon: SellerIcon1,
      enable: true,
    },
    {
      key: '2',
      text: t('menuSeller.menu2'),
      url: '/seller/videos',
      icon: SellerIcon2,
      enable: true,
    },
    {
      key: '3',
      text: t('menuSeller.menu3'),
      url: '/seller/my-page',
      icon: SellerIcon3,
      enable: true,
    },
    {
      key: '4',
      text: t('menuSeller.menu4'),
      url: '/seller/contact',
      icon: SellerIcon4,
      enable: true,
    },
  ];
  const menuLeftAdmin = [
    {
      key: '13',
      text: t('menuAI'),
      icon: SellerIcon2,
      url: '/admin/ai/gen_video',
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[2].en || roleUser === STAFF_ROLE[1].en,
    },
    {
      key: '14',
      text: t('genlink'),
      icon: SellerIcon1,
      url: '/admin/genlink',
      enable: roleUser === STAFF_ROLE[0].en,
    },
    {
      key: '1',
      text: t('menuAdmin.menu1'),
      icon: SellerIcon1,
      url: '/admin/dashboard',
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[2].en,
      children: [
        {
          key: '1.1',
          text: t('menuAdmin.subMenu1.menu1'),
          url: '/admin/sales',
          enable: true,
        },
        {
          key: '1.2',
          text: t('menuAdmin.subMenu1.menu2'),
          url: '/admin/spends',
          enable: true,
        },
      ],
    },
    {
      key: '2',
      text: t('menuAdmin.menu2'),
      url: '/admin/videos',
      icon: SellerIcon2,
      enable: roleUser !== STAFF_ROLE[2].en,
      children: [
        {
          key: '2.1',
          text: t('menuAdmin.subMenu2.menu1'),
          url: '/admin/videos',
          enable: roleUser !== STAFF_ROLE[2].en,
        },
        {
          key: '2.2',
          text: t('menuAdmin.subMenu2.menu2'),
          url: '/admin/master',
          enable: roleUser !== STAFF_ROLE[2].en && roleUser !== STAFF_ROLE[5].en && roleUser !== STAFF_ROLE[6].en,
        },
        {
          key: '2.3',
          // text: t('menuAdmin.subMenu2.menu2'),
          text: 'Model',
          url: '/admin/master/model',
          enable: roleUser !== STAFF_ROLE[2].en && roleUser !== STAFF_ROLE[5].en && roleUser !== STAFF_ROLE[6].en,
        },
      ],
    },
    {
      key: '3',
      text: t('menuAdmin.menu3'),
      icon: SellerIcon3,
      url: '/admin/creators',
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
    },
    {
      key: '4',
      text: t('menuAdmin.menu4'),
      url: '/admin/users',
      icon: AdminIcon4,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
    },
    {
      key: '5',
      text: t('menuAdmin.menu5'),
      url: '/admin/plans',
      icon: AdminIcon5,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
      children: [
        {
          key: '5.1',
          text: t('menuAdmin.subMenu5.menu1'),
          url: '/admin/plans',
          enable: true,
        },
        {
          key: '5.2',
          text: t('menuAdmin.subMenu5.menu2'),
          url: '/admin/points',
          enable: true,
        },
        {
          key: '5.3',
          text: t('menuAdmin.subMenu5.menu3'),
          url: '/admin/plans-ai',
          enable: true,
        },
      ],
    },
    {
      key: '6',
      text: t('menuAdmin.menu6'),
      url: '/admin/campaigns',
      icon: AdminIcon6,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
    },
    {
      key: '7',
      text: t('menuAdmin.menu7'),
      url: '/admin/contact',
      icon: AdminIcon7,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
    },
    {
      key: '8',
      text: t('menuAdmin.menu8'),
      icon: AdminIcon8,
      url: '/admin/staffs',
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en,
      // children: [
      //   {
      //     key: '8.1',
      //     text: t('menuAdmin.subMenu8.menu1'),
      //     url: '/admin/staffs',
      //     enable: true,
      //   },
      //   {
      //     key: '8.2',
      //     text: t('menuAdmin.subMenu8.menu2'),
      //     url: '/admin/8/2',
      //     enable: true,
      //   },
      // ],
    },
    {
      key: '9',
      text: t('menuAdmin.menu9'),
      icon: AdminIcon9,
      url: '/admin/news',
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
    },
    {
      key: '10',
      text: t('menuAdmin.menu10'),
      url: '/admin/banners',
      icon: AdminIcon10,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[3].en,
    },
    {
      key: '11',
      text: t('menuAdmin.menu11'),
      url: '/admin/setting',
      icon: AdminIcon11,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[2].en,
    },
    {
      key: '12',
      text: t('menuAdmin.menu12'),
      url: '/admin/qa',
      icon: AdminIcon12,
      enable: roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[1].en || roleUser === STAFF_ROLE[2].en,
    },
  ];
  const visibleMenuLeft: boolean =
    history.location.pathname.includes('admin') || history.location.pathname.includes('seller');
  const menuLeft = history.location.pathname.includes('seller') ? menuLeftSeller : menuLeftAdmin;
  const checkRedirect = () => {
    if (!isFetching && history.location.pathname === '/admin') {
      if (roleUser === STAFF_ROLE[0].en || roleUser === STAFF_ROLE[2].en) {
        return <Redirect exact from="/admin" to="/admin/dashboard" />;
      } else return <Redirect exact from="/admin" to="/admin/videos" />;
    }
  };

  return (
    <ConfigProvider locale={jaJP}>
      <SideNavProvider>
        <div className={`${styles.pageWrapper} ${visibleMenuLeft ? 'height-100vh' : 'bg-newMain'}`}>
          {visibleMenuLeft && <SideNav menu={menuLeft} />}
          <div className={`${styles.mainWrapper} ${history.location.pathname === '/' && 'mainVideo'}`}>
            {history.location.pathname !== '/' && !history.location.pathname.includes('/landing') && (
              <PageHeader
                menu={
                  history.location.pathname.includes('admin') || history.location.pathname.includes('seller')
                    ? []
                    : menuTop
                }
                infoUser={isAuthenticated ? infoUser : null}
                className="headerWhite"
              />
            )}
            <div
              className={`${!history.location.pathname.includes('/landing') && 'pageContent'} ${
                !urlSeller && !urlAdmin && !urlListVideo && styles.contentUser
              } ${history.location.pathname.includes('/landing') && 'headerTop'}`}
            >
              <Suspense fallback={null}>
                {listRouter?.map((val: any, ind: number) => {
                  return (
                    <Fragment key={ind}>
                      <Switch>
                        <Redirect exact from="/seller" to="/seller/dashboard" />
                        {checkRedirect()}
                        <Route exact path={val.path} component={val.component} />
                      </Switch>
                    </Fragment>
                  );
                })}
              </Suspense>
            </div>
            {!urlSeller && !urlAdmin && history.location.pathname !== '/' && <Footer />}
          </div>
        </div>
      </SideNavProvider>
    </ConfigProvider>
  );
}
